import styled from 'styled-components'

import { ButtonLink, Container, Icon, Tag, Text } from 'common/UI'
import { WhereToFindUsStoryblok } from 'common/types'
import { textByLine } from 'common/utils/content'

import { Editable } from '../Editable'
import { ClinicMap } from './ClinicMap'

type Props = {
  block: WhereToFindUsStoryblok
}

export const WhreeToFindUs = ({ block, ...props }: Props): JSX.Element => {
  const {
    clinic,
    tag_text,
    title,
    description,
    informations,
    directions_button_label,
  } = block

  const coordinates = clinic?.content?.coordinates.split(',')

  return (
    <SectionContainer>
      <MapContainer {...props}>
        {typeof clinic !== 'string' && coordinates && (
          <ClinicMap
            clinic={{
              id: clinic.uuid,
              latitude: coordinates[0],
              longitude: coordinates[1],
            }}
            initialPosition={{
              zoom: 10,
              latitude: coordinates[0] ? +coordinates[0] : undefined,
              longitude: coordinates[1] ? +coordinates[1] : undefined,
            }}
          />
        )}

        <ClinicInformationCard>
          <Editable block={block}>
            <ClinicInformationCardHeader>
              {tag_text && <Tag label={tag_text} />}
              {title && (
                <Text as="p" variant="title/large">
                  {title}
                </Text>
              )}

              {description && (
                <Text as="p" variant="twenty">
                  {description}
                </Text>
              )}
            </ClinicInformationCardHeader>

            <ClinicInformationCardContent>
              {informations &&
                informations.map((information) => {
                  const { _uid, label, text } = information
                  return (
                    <Editable block={information} key={_uid}>
                      <ClinicInformationCardContentItem>
                        {label && (
                          <Text
                            as="p"
                            variant="eighteen/regular"
                            css={{ opacity: '60%' }}
                          >
                            {label}
                          </Text>
                        )}

                        {text &&
                          textByLine(text, (part) => {
                            return (
                              <Text as="p" variant="eighteen">
                                {part}
                              </Text>
                            )
                          })}
                      </ClinicInformationCardContentItem>
                    </Editable>
                  )
                })}
            </ClinicInformationCardContent>

            <ButtonLink
              variant="ghost"
              href={`https://maps.google.com?daddr=${encodeURIComponent(
                clinic?.content.address
              )}`}
              target="_blank"
              rel="noopener noreferrer"
              css={{ maxWidth: 'fit-content', marginTop: '1.25rem' }}
            >
              {directions_button_label}
              <Icon icon="external" size={16} className="ml-2.5 opacity-60" />
            </ButtonLink>
          </Editable>
        </ClinicInformationCard>
      </MapContainer>
    </SectionContainer>
  )
}

const SectionContainer = styled(Container)`
  padding-top: 1.25rem;
  padding-bottom: 5rem;

  padding-left: 1.25rem;
  padding-right: 1.25rem;

  ${({ theme }) => theme.media.lg} {
    padding-left: 5rem;
    padding-right: 5rem;
  }
`

const MapContainer = styled.section`
  display: flex;
  position: relative;
  flex-direction: column-reverse;

  ${({ theme }) => theme.media.lg} {
    flex-direction: row;
  }
`

const ClinicInformationCard = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5rem;

  ${({ theme }) => theme.media.lg} {
    background-color: ${({ theme }) => theme.colors.background.default};
    margin-bottom: 0rem;

    position: absolute;
    top: 8px;
    right: 8px;
    bottom: 8px;

    max-width: 442px;

    padding: 2rem;
    border-radius: 1.25rem;
  }
`

const ClinicInformationCardHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const ClinicInformationCardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  margin-top: 2rem;
`

const ClinicInformationCardContentItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`
